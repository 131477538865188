import React from "react"

import "react-responsive-modal/styles.css"
import { Modal } from "react-responsive-modal"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Puppy from "../components/puppy"

const Process = () => {
  return (
    <Layout>
      <SEO title="Our Process" />

      <section className="pt-3 pb-3">
        <div className="container mx-auto">
          <div className="card mb-5">
            <div className="card-header text-center text-black font-weight-bold">
              Our Process
            </div>

            <div className="card-body">
              <div className="row justify-content-center">
                <p>
                  Our process consists on depositing $500 to reserve a puppy.
                  The deposit is refundable should something happen to the puppy
                  while in our care or if the vet declares the puppy is not
                  healthy while in our care. The deposit is nonrefundable if the
                  family changes their mind. The family will receive an invoice.
                  In order to create an invoice we need your first name, last
                  name, address, and email.
                </p>
                <p>
                  All puppies come with a spay/neuter contract. The spay/neuter
                  contract must be signed before the puppy is picked up. If the
                  family does not sign the contract before pick up we reserve
                  the right to not refund the deposit and find the puppy a new
                  home. Families will be held responsible for keeping their side
                  of the contract. Royal Sunny Doodles will request spay/neuter
                  certificates to be emailed to us when the puppies are of age.
                  Failure to provide proof of spay/neuter will result in legal
                  action taken.
                </p>
                <p>
                  All puppies will be expected to be picked up within a week
                  after they're eligible to go home. Royal Sunny Doodles will
                  schedule pick up days with each family. Families are expected
                  to arrive at the planned scheduled day to pick up their
                  puppies.
                </p>
                <p>
                  On pick up day the remaining balance of the puppy is due. Our
                  puppies will not be able to go to their new home unless the
                  remaining balance is paid. The remaning balance can be paid at
                  anytime prior to pickup.
                </p>
              </div>
            </div>
            {/* <div className="card-footer text-muted ">
              <p className="text-white">x puppies remaining</p>
            </div> */}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Process
